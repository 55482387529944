@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ninja;
  src: url(assets/AssassinNinja.ttf);
}
@layer base {
  ul,
  ol {
    list-style: revert;
  }
}

/* Customs */
.ninja {
  font-family: ninja !important;
}

:root {
  --kaizen-yellow: #ffdd02;
}

.scrollbar-left {
  direction: rtl;
}

.scrollbar::-webkit-scrollbar {
  width: 0.375rem;
}

.scrollbar::-webkit-scrollbar-track {
  background: #baa51b;
  border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 100vh;
}
